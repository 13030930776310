import { TableTypes } from "@bptypes/tables";
import Marquee from "@components/Marquee";
import ImageFallback from "@components/core/Image/ImageFallback";
import { TableCell, TableRow } from "@components/core/Table";
import { AddToCart, AddToPlaylist, AddToQueue, Play } from "@components/interaction";
import { ItemLoader } from "@components/loaders";
import { ArtistNames, renderArtistNames } from "@components/shared/Artists/ArtistNames";
import LabelLink from "@components/shared/Labels/LabelLink";
import { ArtworkAndControls, Exclusive, LoaderWrapper, ReleaseName, TrackNo } from "@components/tables/Tables.shared.style";
import { TRACK_PLACEHOLDER } from "@lib/constants";
import { cls } from "@lib/css";
import { getItemPrice } from "@lib/utils";
import { CartItem } from "@models/Cart";
import { Audioformat } from "@models/audio-format";
import { Track } from "@models/track";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import ChartsTracksTableItem from "./ChartsTracksTableItem";

interface RenderTrackProps {
	track: Track;
	index: number;
	trackTableType?: TableTypes;
	showNumbers?: boolean;
	location?: string;
	dataTestId?: string;
	currentTrack?: Track;
	filteredTracks?: Track[];
	isLoading?: boolean;
	showReleaseDate?: boolean;
	showGenre?: boolean;
	isCartTable?: boolean;
	audioFormats?: Record<number, Audioformat>;
	dragHandleProps?: DraggableProvidedDragHandleProps;
	chartDeleteTrack?: (trackId: number) => void;
	handleTrackClick: (track: Track, index: number) => void;
	itemCartActions?: (cartItem: CartItem<Track>) => JSX.Element;
	formatSelector?: (cartItem: CartItem<Track>) => JSX.Element;
}

const IMG_WIDTH = TRACK_PLACEHOLDER.image.width;
const IMG_HEIGHT = TRACK_PLACEHOLDER.image.height;
const FALLBACK_URL = TRACK_PLACEHOLDER.href;

const TrackTableItem = (props: RenderTrackProps) => {
	const { t } = useTranslation("translation");
	const {
		track,
		index,
		dragHandleProps,
		trackTableType,
		showNumbers = true,
		chartDeleteTrack,
		location,
		dataTestId,
		currentTrack,
		filteredTracks,
		handleTrackClick,
		showReleaseDate,
		showGenre,
		isCartTable,
		itemCartActions,
		formatSelector,
		audioFormats,
		isLoading,
	} = props;
	return trackTableType === "CHART" ?
			(
				<ChartsTracksTableItem
					key={`tracks-${track.id}`}
					track={track}
					showNumbers={showNumbers}
					index={index}
					chartDeleteTrack={chartDeleteTrack}
					dragHandleProps={dragHandleProps}
					location={location}
				/>
			) :
			(
				<TableRow
					data-testid={dataTestId}
					key={`tracks-${track.id}`}
					className={cls(
						"row",
						"tracks-table",
						currentTrack && currentTrack.id === track.id ? "current" : undefined,
					)}
				>
					<TableCell className="controls">
						<ArtworkAndControls>
							<Link
								href={`/release/${track.release.slug}/${track.release.id}`}
								prefetch={false}
								title={track.release.name}
								className="artwork"
							>
								<ImageFallback
									src={track.release?.image?.uri}
									srcSize="sm"
									alt={track.release.name}
									width={IMG_WIDTH}
									height={IMG_HEIGHT}
									fallbackSrc={FALLBACK_URL}
									blurDataURL={FALLBACK_URL}
								/>
								{track.exclusive && <Exclusive>{`${t("Exclusive")}`}</Exclusive>}
							</Link>
							{showNumbers && <TrackNo>{index + 1}</TrackNo>}
							<span className="fade">
								<Play
									tracks={[track]}
									virtualQueue={filteredTracks}
								/>
							</span>
							<span className="fade">
								<AddToQueue tracks={[track]} />
							</span>
							<span className="fade">
								<AddToPlaylist tracks={[track]} />
							</span>
						</ArtworkAndControls>
					</TableCell>
					<TableCell className="cell title">
						<div className="container">
							<Link
								href={`/track/${track.slug}/${track.id}`}
								prefetch={false}
								title={track.name}
								onClick={() => handleTrackClick(track, index)}
							>
								<Marquee shouldCheckPresence maxWidth="17rem">
									<ReleaseName>
										{track.name} <span>{track.mix_name}</span>{" "}
									</ReleaseName>
								</Marquee>
							</Link>
							{track.artists && track.artists.length > 0 && (
								<Marquee shouldCheckPresence maxWidth="17rem">
									<ArtistNames>
										{renderArtistNames(track.artists, { location })}
									</ArtistNames>
								</Marquee>
							)}
						</div>
					</TableCell>
					<TableCell className="cell label">
						<LabelLink label={track.release.label} location={location} withMarquee />
						{track.remixers && track.remixers.length > 0 && (
							<Marquee shouldCheckPresence maxWidth="17rem">
								<ArtistNames>
									{renderArtistNames(track.remixers, { location })}
								</ArtistNames>
							</Marquee>
						)}
					</TableCell>
					<TableCell className="cell bpm">
						{(showGenre || track?.sub_genre) && (
							<Link
								href={`/genre/${track.genre.slug}/${track.genre.id}`}
								prefetch={false}
								title={track.genre.name}
							>
								<Marquee shouldCheckPresence maxWidth="17rem">
									{showGenre && track.genre.name}
									{
										track?.sub_genre && track.sub_genre.name && (
											<>{showGenre && <span> | </span>} {track.sub_genre.name}</>
										)
									}
								</Marquee>
							</Link>
						)}
						<div>
							{track.bpm} BPM {track?.key && `- ${track.key?.name}`}
						</div>
					</TableCell>
					{showReleaseDate && (
						<TableCell className="cell date">{track.new_release_date}</TableCell>
					)}
					{isCartTable && track.cart_item_data && (
						<>
							{isLoading ?
									(
										<LoaderWrapper>
											<ItemLoader />
										</LoaderWrapper>
									) :
									(
										<>
											{formatSelector && (
												<TableCell className="cell format">
													{formatSelector(track.cart_item_data)}
												</TableCell>
											)}
											<TableCell className="cell price">
												{getItemPrice({
													item: track.cart_item_data,
													audioFormatsObject: audioFormats || {},
												})}
											</TableCell>
											{itemCartActions && (
												<TableCell className="cell cart-actions">
													{itemCartActions(track.cart_item_data)}
												</TableCell>
											)}
										</>
									)}
						</>
					)}
					{!isCartTable && (
						<TableCell className="cell card">
							<span className="fade">
								<AddToCart location={location} track={track} />
							</span>
						</TableCell>
					)}
				</TableRow>
			);
};

export default TrackTableItem;
