import styled from "styled-components";
import { Wrapper } from "../Tables.shared.style";

export const TracksWrapper = styled(Wrapper)`

  a.artwork > img {
    min-width: 2.375rem;
  }

  .droppable {
    width: 100%;
  }

  .cell {
    &.bpm {
      a {
        white-space: nowrap;
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
      }
    }
    &.format {
      padding-left: 0;
    }
  }

  .label {
    width: 7.5rem;
    min-width: 7.5rem;
  }

  .date {
    width: 7.5rem;
    min-width: 7.5rem;
  }

  .bpm {
    width: 9.375rem;
    min-width: 9.375rem;
    display: block;
    text-overflow: elipsis;
    white-space: no-wrap;
  }

  .price {
    width: 5rem;
    min-width: 5rem;
  }

  i.arrow {
    margin: 0.25rem;
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;

    border-left: 0.25rem solid transparent;
    border-right: 0.25rem solid transparent;
    border-top: 0.25rem solid ${(props) => props.theme.colors.green[300].accent};

    &.desc {
      transform: rotate(180deg);
    }
  }
`;

export const ItemControls = styled.div`
  height: 2.5rem;
  width: 2.5rem;
  transition: width 0.2s ease-in-out;
  color: ${(props) => props.theme.colors.neutrals.primary.white};
`;

export const ItemButtons = styled.div`
  display: flex;

  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 2.5rem;
  border-left: 0.063rem solid ${(props) => props.theme.colors.neutrals.primary[800]};
  &.borderless {
    border-left: none;
  }
`;
