import {
	Table,
	TableCell,
	TableData,
	TableHeader,
} from "@components/core/Table";

import { usePlayerState } from "@lib/context/player";
import { cls } from "@lib/css";
import { CartItem } from "@models/Cart";

import { TableTypes } from "@bptypes/tables";
import { SORT_MAP, TRACK_SORT_OPTIONS } from "@lib/constants/search";
import { useStrictDroppable } from "@lib/hooks/useStrictDroppable";
import { Audioformat } from "@models/audio-format";
import { Track } from "@models/track";
import {
	pushProductClickEvent,
	pushProductImpressionEvent,
} from "@utils/dataLayer";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { Fragment, useCallback, useEffect, useState } from "react";
import {
	DragDropContext,
	Draggable,
	Droppable,
	OnDragEndResponder,
} from "react-beautiful-dnd";
import Loader from "./Loader";
import {
	TracksWrapper,
} from "./TracksTable.style";
import TrackTableItem from "./TracksTableItems/TrackTableItem";
interface Props {
	isLoading: boolean;
	cartTracks?: CartItem<Track>[];
	tracks?: Track[];
	showNumbers?: boolean;
	showHeader?: boolean;
	isPaginated?: boolean;
	chartDeleteTrack?: (trackId: number) => void;
	trackTableType?: TableTypes;
	itemCartActions?: (cartItem: CartItem<Track>) => JSX.Element;
	formatSelector?: (cartItem: CartItem<Track>) => JSX.Element;
	audioFormats?: Record<number, Audioformat>;
	onChange?: (filters: Record<string, string>) => void;
	draggable?: boolean;
	onDragEnd?: OnDragEndResponder;
	location?: string;
	dataTestId?: string;
	showReleaseDate?: boolean;
	showGenre?: boolean;
	page?: number;
	perPage?: number;
	loadingItems?: number[];
}

const TracksTable: React.FC<Props> = ({
	isLoading,
	cartTracks,
	itemCartActions,
	formatSelector,
	audioFormats,
	tracks = [],
	trackTableType,
	chartDeleteTrack,
	showNumbers = true,
	showHeader = true,
	draggable = false,
	isPaginated = false,
	onChange,
	onDragEnd,
	location,
	dataTestId = "tracks-table-row",
	showReleaseDate = true,
	showGenre = true,
	page = 1,
	perPage = 0,
	loadingItems,
}) => {
	const router = useRouter();
	const { t } = useTranslation("translation");
	const [/* sortIcon */, setSortIcon] = useState<string | undefined>(); // Todo: Remove this?
	const { currentTrack } = usePlayerState();
	const { order_by } = router.query;
	const [dndEnabled] = useStrictDroppable(false);

	const filteredTracks = cartTracks ?
			cartTracks
				.filter((t) => t.item !== undefined)
				.map((c) => {
					c.item.cart_item_data = c;
					return c.item;
				}) :
		tracks;

	const getProductEventData = useCallback(
		(track: Track, index: number) => ({
			name: track.name,
			position: index + 1,
			label: track?.release?.label?.name,
			list: location || null,
			artists: track?.artists?.map((a) => a.name).join(", "),
			remixers: track?.remixers?.map((r) => r.name).join(", "),
			genres: track?.genre?.name,
			subGenres: track?.sub_genre?.name || null,
			preOrder: track?.pre_order,
			type: "product",
			category: "Tracks",
			variant: "track",
			price: track?.price?.value,
			imageUrl: track?.release?.image?.uri,
		}),
		[location],
	);

	useEffect(() => {
		if (filteredTracks && filteredTracks.length > 0) {
			pushProductImpressionEvent({
				ecommerce: {
					currencyCode: filteredTracks[0]?.price?.code || "",
					impressions: filteredTracks?.map(getProductEventData),
				},
			});
		}
	}, []);

	const handleTrackClick = (track: Track, index: number) => {
		pushProductClickEvent({
			ecommerce: {
				currencyCode: track?.price?.code || "",
				click: {
					actionField: {
						list: location || "",
					},
					products: [getProductEventData(track, index)],
				},
			},
		});
	};

	const isCartTable = cartTracks !== undefined;

	const sortColumn = (sortKey: string) => {
		let trackSortKey;
		let trackSortIcon;

		if (order_by && order_by?.includes(sortKey)) {
			const trackSortOption = TRACK_SORT_OPTIONS.filter(
				(item) => item.queryStr === order_by,
			);

			if (trackSortOption.length > 0) {
				trackSortKey = trackSortOption[0].nextQueryStr;
				trackSortIcon = trackSortOption[0].icon;
			}
		} else {
			const trackSortOption = TRACK_SORT_OPTIONS.filter(
				(item) => item.queryStr === sortKey,
			);

			if (trackSortOption.length > 0) {
				trackSortKey = trackSortOption[0].value;
				trackSortIcon = trackSortOption[0].icon;
			}
		}
		setSortIcon(trackSortIcon);
		applySort(trackSortKey);
	};

	const applySort = (sort?: string) => {
		const data = {} as Record<string, string>;
		data["order_by"] = "";
		if (onChange) {
			if (sort !== undefined) {
				const parts = sort.split("-");
				data["order_by"] = `${parts[1] === "asc" ? "" : "-"}${SORT_MAP[parts[0]]
				}`;
			}

			onChange(data);
		}
	};

	const renderSortArrow = (sortKey: string) => {
		return order_by === sortKey ?
				(
					<i className="arrow desc" />
				) :
			order_by === `-${sortKey}` ?
					(
						<i className="arrow" />
					) :
					(
						""
					);
	};
	if (isLoading) {
		return <Loader />;
	}

	const inheritedTrackProps = {
		trackTableType,
		showNumbers,
		chartDeleteTrack,
		location,
		dataTestId,
		currentTrack,
		filteredTracks,
		handleTrackClick,
		showReleaseDate,
		showGenre,
		isCartTable,
		itemCartActions,
		formatSelector,
		audioFormats,
	};

	return (
		<TracksWrapper
			className={cls(
				showNumbers ? "numbers" : undefined,
				isCartTable ? "cart-table" : undefined,
			)}
		>
			<Table className="tracks-table">
				{showHeader ?
						(
							<TableHeader className="row">
								<TableCell className="controls">&nbsp;</TableCell>
								<TableCell className="title" onClick={() => sortColumn("name")}>
									{`${t("Title")} / ${t("Artists")}`}
									{renderSortArrow("name")}
								</TableCell>
								<TableCell className="label" onClick={() => sortColumn("label")}>
									{`${t("Label.Title")} / ${t("Remixers")}`}
									{renderSortArrow("label")}
								</TableCell>
								<TableCell className="bpm" onClick={() => sortColumn("genre")}>
									{`${showGenre ? t("Genre") : t("Subgenre")} / BPM & ${t("Key")}`}
									{renderSortArrow("genre")}
								</TableCell>
								{showReleaseDate && (
									<TableCell
										className="date"
										onClick={() => sortColumn("release_date")}
									>
										{`${t("Released")}`}
										{renderSortArrow("release_date")}
									</TableCell>
								)}
								{isCartTable && (
									<>
										<TableCell
											className="format"
										>
											{t("Format")}
										</TableCell>
										<TableCell
											className="price"
										>
											{t("Price")}
										</TableCell>
										<TableCell className={cls("cart-actions")}>
											{t("CartActions")}
										</TableCell>
									</>
								)}
								{!isCartTable && <TableCell className="card">&nbsp;</TableCell>}
							</TableHeader>
						) :
					null}
				{draggable && onDragEnd ?
						(
							<DragDropContext onDragEnd={onDragEnd}>
								<TableData>
									{dndEnabled && (
										<Droppable droppableId="chart-tracks-droppable">
											{(provided) => (
												<div
													className="droppable"
													ref={provided.innerRef}
													{...provided.droppableProps}
												>
													{filteredTracks.map((track, index) => {
														const trackIndex = isPaginated ?
															perPage * (page - 1) + index :
															index;
														return (
															<Draggable
																draggableId={`chart-track-${track.id}`}
																index={index}
																key={`chart-track-${track.id}`}
															>
																{(innerProvided) => (
																	<div
																		ref={innerProvided.innerRef}
																		{...innerProvided.draggableProps}
																	>
																		<TrackTableItem
																			track={track}
																			index={trackIndex}
																			dragHandleProps={
																				innerProvided.dragHandleProps || undefined
																			}
																			{...inheritedTrackProps}
																		/>
																	</div>
																)}
															</Draggable>
														);
													})}
													{provided.placeholder}
												</div>
											)}
										</Droppable>
									)}
								</TableData>
							</DragDropContext>
						) :
						(
							<TableData>
								{filteredTracks.map((track, index) => {
									const isLoading = track.cart_item_data && loadingItems?.includes(track.cart_item_data?.id);
									const trackIndex = isPaginated ?
										perPage * (page - 1) + index :
										index;
									return (
										<Fragment key={`tracks-${track.id}`}>
											<TrackTableItem
												track={track}
												index={trackIndex}
												isLoading={isLoading}
												{...inheritedTrackProps}
											/>
										</Fragment>
									);
								})}
							</TableData>
						)}
			</Table>
		</TracksWrapper>
	);
};

export default TracksTable;
